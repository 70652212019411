<section class="content-hero">
  <owl-carousel [options]="carouselOptions" [items]="services" [carouselClasses]="['owl-theme', 'row', 'sliding']">
    <div class="item" *ngFor="let servicio of services;let i= index">
      <img class="slide-image" src="{{servicio.img}}"  alt="">
      <div class="slide-text">
        <div class="content-icon">
        <img src="{{servicio.icon}}" alt="{{ servicio.title }}">
        </div>
        <div class="text-slide">
          <h4>{{servicio.title}}</h4>
          <a routerLink="/servicios/{{ servicio.slug }}">Ver más</a>
        </div>
      </div>
    </div>
  </owl-carousel>
</section>
