<section class="content-header">
  <header>
    <div class="preheader">
      <div class="container">
        <div class="row">
          <div class="row middle-xs col-xs-12 col-sm-6 center-xs start-sm preheader-info">
            <img src="../../../../assets/images/icons/icon-whatsapp.svg" alt="Whatsapp">
            <p class="preheader-text">(57) 8-7631252 /8 - 7631254 Fax: 8-7602661</p>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="col-xs row middle-xs center-xs end-sm">
              <div class="preheader-language preheader-text">
                <a href="">ES</a>/<a href="">EN</a>
              </div>
              <div class="row middle-xs">
                <a href="#">
                  <img src="../../../../assets/images/icons/icon-facebook.svg" alt="Facebook">
                </a>
                <a href="#">
                  <img src="../../../../assets/images/icons/icon-instagram.svg" alt="Instagram">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="logo">
        <figure>
          <a routerLink="/">
            <img src="../../../../assets/images/logo/logo.svg" alt="Logo Explorer">
          </a>
        </figure>
      </div>
      <div class="menu-icon" (click)="menu()" [ngClass]="{'open': status}">
        <div id="nav-icon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="row center-xs" >
      <nav class="menu" [ngClass]="{'open': status}">
        <ul class="menu-list">
          <li class="menu-list-item-link content-submenu" routerLinkActive="active">
            <a class="menu-link">
              Nosotros
            </a>
            <ul class="submenu">
              <li>
                <a (click)="menu()" routerLink="/quienes-somos">Quienes somos</a>
              </li>
              <li>
                <a (click)="menu()" routerLink="/historia">Historia</a>
              </li>
              <li>
                <a (click)="menu()" routerLink="/institucional">Institucional</a>
              </li>
              <li>
                <a (click)="menu()" routerLink="/certificaciones">Certificaciones</a>
              </li>
            </ul>
          </li>
          <li class="menu-list-item-link content-submenu" routerLinkActive="active">
            <a routerLink="/servicios" class="menu-link">
              Servicios
            </a>
            <ul class="submenu">
              <li *ngFor="let servicio of services">
                <a (click)="menu()" routerLink="/servicios/{{ servicio.slug }}">{{ servicio.title }}</a>
              </li>
             <!--  <li>
                <a href="#">Servicio 2</a>
                <ul class="submenu">
                  <li>
                    <a href="#">Servicio 1</a>
                  </li>
                  <li>
                    <a href="#">Servicio 2</a>
                  </li>
                  <li>
                    <a href="#">Servicio 3</a>
                  </li>
                  <li>
                    <a href="#">Servicio 4</a>
                  </li>
                </ul>
              </li> -->
            </ul>
          </li>
          <li class="menu-list-item-link" routerLinkActive="active">
            <a (click)="menu()" routerLink="/alquiler"  class="menu-link">Alquiler</a>
          </li>
          <li class="menu-list-item-link" routerLinkActive="active">
            <a (click)="menu()" routerLink="/eventos" class="menu-link">Eventos</a>
          </li>
          <li class="menu-list-item-link" routerLinkActive="active">
            <a (click)="menu()" routerLink="/clientes" class="menu-link">Clientes</a>
            <!-- <ul class="submenu">
              <li>
                <a href="#">Item</a>
              </li>
              <li>
                <a href="#">Item</a>
              </li>
              <li>
                <a href="#">Item</a>
              </li>
              <li>
                <a href="#">Item</a>
              </li>
            </ul> -->
          </li>
          <li class="menu-list-item-link" routerLinkActive="active">
            <a (click)="menu()" routerLink="/contacto" class="menu-link">Contacto</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</section>
